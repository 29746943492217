<template>
  <div id="home" class="wrap-home">
    <div class="small">
      <div class="bg">
        <div class="red"></div>
        <div class="yellow"></div>
        <div class="blue"></div>
      </div>

      <div class="mobile">
        <div class="uk-container">
          <div class="box-center">
            <h2>LET’S</h2>
            <img src="@/assets/images/cup.png">
          </div>

          <div class="box-detail">
            <div class="box-item-red">
              <div class="wrap">
                <a
                  @click="clickIcon('promotions')"
                  id="bannerPromotionLink"
                  class="box-icon" >
                  <div>
                    <p>+1</p>
                  </div>
                </a>
                <a
                  @click="clickIcon('promotions')"
                  id="bannerPromotionLink"
                  class="item" >
                  <p style="font-size: 1.25rem;
                            padding-top: 14px;">
                    More Promotion
                  </p>
                </a>
              </div>
            </div>

            <div v-if="false" class="box-item-green">
              <div class="wrap">
                <div class="item">
                  <p>Opening Hours</p>
                </div>
                <div
                  @click="clickIcon('store')"
                  id="bannerStoreLink"
                  class="box-icon"
                >
                  <div>
                    <img src="@/assets/icons/ic-sub-watch.svg">
                  </div>
                </div>
              </div>
            </div>

            <!-- <div v-if="false" class="box-item-blue">
              <div class="wrap">
                <div
                  @click="clickIcon('application')"
                  id="bannerApplicationLink"
                  class="box-icon"
                >
                  <div>
                    <img src="@/assets/icons/ic-download.svg">
                  </div>
                </div>
                <div class="item">
                  <p style="margin-top: -4px;">DOWNLOAD APP<br>GET 1 FREE DRINK</p>
                </div>
              </div>
            </div> -->

            <!-- <div class="box-item-yellow">
              <div class="wrap">
                <div class="item">
                  <p>Open 24 October 2019</p>
                </div>
                <div
                  id="store-link"
                  class="box-icon"
                >
                  <div>
                    <p>24</p>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="box-item-orange">
              <div class="wrap">
                <a
                  @click="clickIcon('store')"
                  id="bannerStoreLink"
                  class="item" >
                  <p>Location</p>
                </a>
                <a
                  @click="clickIcon('store')"
                  id="bannerStoreLink"
                  class="box-icon" >
                  <div id="bannerStoreLink"></div>
                </a>
              </div>
            </div>

            <div class="box-item-menu">
              <div class="wrap">
                <a
                  @click="clickIcon('menu')"
                  id="bannerMenuLink"
                  class="box-icon" >
                  <div>
                    <!-- <img src="@/assets/icons/ic-picker.svg"> -->
                  </div>
                </a>
                <a
                  @click="clickIcon('menu')"
                  id="bannerMenuLink"
                  class="item" >
                  <p>TRUECOFFEE GO MENU</p>
                </a>
              </div>
            </div>

            <!-- <div class="box-item-blue">
              <div class="wrap">
                @click="toLink('https://goo.gl/maps/DicUfWedtnfDcgXG7')"
                <a
                  @click="clickIcon('application')"
                  id="bannerApplicationLink"
                  class="item" >
                  <p>Application</p>
                </a>
                <a
                  @click="clickIcon('application')"
                  id="bannerApplicationLink"
                  class="box-icon" >
                  <div>
                    <img src="@/assets/icons/ic-picker.svg">
                  </div>
                </a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="medium">
      <div class="bg">
        <div class="red"></div>
        <div class="yellow"></div>
        <div class="blue"></div>
      </div>

      <div class="box-logo">
        <div class="box-center">
          <h2>LET’S</h2>
          <img src="@/assets/images/cup.png">
        </div>
      </div>

      <div class="box-detail">
        <div class="box-item-red">
          <div class="wrap">
            <a
              id="bannerPromotionLink"
              class="box-icon"
              @mouseover="mouseOver('red')"
              @mouseleave="defaulfHover"
              @click="clickIcon('promotions')" >
              <div>
                <p>+1</p>
              </div>
            </a>
            <div
              class="item"
              :class="[isHover.red ? 'hover-box-red' : '']" >
              <p style="font-size: 1.25rem;
                        padding-top: 14px;" >
                More Promotion
              </p>
            </div>
          </div>
        </div>

        <div v-if="false" class="box-item-green">
          <div class="wrap">
            <a
              id="bannerStoreLink"
              class="box-icon"
              @mouseover="mouseOver('green')"
              @mouseleave="defaulfHover"
              @click="clickIcon('store')" >
              <div>
                <img src="@/assets/icons/ic-sub-watch.svg">
              </div>
            </a>
            <div
              class="item"
              :class="[isHover.green ? 'hover-box-green' : '']" >
              <p>Opening Hours</p>
            </div>
          </div>
        </div>

        <!-- <div class="box-item-blue">
          <div class="wrap"> -->
            <!-- :class="[isHover.blue ? 'hover-box-blue' : '']" -->
            <!-- <div
              class="item"
              :class="[isHover.blue ? 'hover-box-blue' : '']" >
              <p style="margin-top: -4px;">DOWNLOAD APP<br>Promotion</p>
            </div>

            <a
              id="bannerApplicationLink"
              class="box-icon"
              @mouseover="mouseOver('blue')"
              @mouseleave="defaulfHover"
              @click="clickIcon('application')" >
              <div>
                <img src="@/assets/icons/ic-download.svg">
              </div>
            </a>
          </div>
        </div> -->

        <div v-if="false" class="box-item-yellow">
          <div class="wrap">
            <div
              class="item"
              :class="[isHover.yellow ? 'hover-box-yellow' : '']" >
              <p>Open 24 October 2019</p>
            </div>
            <div
              id="bannerStoreLink"
              class="box-icon"
              @mouseover="mouseOver('yellow')"
              @mouseleave="defaulfHover" >
              <div>
                <p>24</p>
              </div>
            </div>
          </div>
        </div>

        <div class="box-item-orange">
          <div class="wrap">
            <a
              id="bannerStoreLink"
              class="box-icon"
              @mouseover="mouseOver('orange')"
              @mouseleave="defaulfHover"
              @click="clickIcon('store')" >
              <div></div>
            </a>
            <div
              class="item"
              :class="[isHover.orange ? 'hover-box-orange' : '']" >
              <p>Location</p>
            </div>
          </div>
        </div>

        <div class="box-item-menu">
          <div class="wrap">
            <div
              class="item"
              :class="[isHover.menu ? 'hover-box-menu' : '']" >
              <p>TRUECOFFEE GO MENU</p>
            </div>
            <a
              id="bannerMenuLink"
              class="box-icon"
              @mouseover="mouseOver('menu')"
              @mouseleave="defaulfHover"
              @click="clickIcon('menu')" >
              <div></div>
            </a>
          </div>
        </div>

        <div class="box-item-twitter">
          <div class="wrap">
            <!-- @click="toLink('https://www.twitter.com/')" -->
            <div
              id="bannerTwitterLink"
              class="box-icon" >
              <div></div>
            </div>
          </div>
        </div>

        <div class="box-item-facebook">
          <div class="wrap">
            <a
              id="bannerFacebookLink"
              @click="toLink('https://www.facebook.com/truecoffeego')"
              class="box-icon" >
              <div></div>
            </a>
          </div>
        </div>

        <div class="box-item-line">
          <div class="wrap">
            <a
              id="bannerLineLink"
              @click="toLink('http://line.me/ti/p/%40truecoffeego')"
              class="box-icon" >
              <div></div>
            </a>
          </div>
        </div>

        <div class="box-item-instagram">
          <div class="wrap">
            <a
              id="bannerInstagramLink"
              @click="toLink('https://www.instagram.com/truecoffeego_')"
              class="box-icon" >
              <div></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isHover: {
        red: false,
        green: false,
        // blue: false,
        yellow: false,
        orange: false,
        menu: false
      }
    }
  },

  methods: {
    mouseOver (color) {
      this.defaulfHover()

      switch (color) {
        case 'red':
          this.isHover.red = true
          break
        case 'green':
          this.isHover.green = true
          break
        // case 'blue':
        //   this.isHover.blue = true
        //   break
        case 'yellow':
          this.isHover.yellow = true
          break
        case 'orange':
          this.isHover.orange = true
          break
        case 'menu':
          this.isHover.menu = true
          break
      }
    },

    defaulfHover () {
      this.isHover.red = false
      this.isHover.green = false
      // this.isHover.blue = false
      this.isHover.yellow = false
      this.isHover.orange = false
      this.isHover.menu = false
    },

    clickIcon (routeName) {
      // if (hash !== 'download') { this.$router.push({ hash: hash }) }
      this.$router.push({ name: routeName })
    },

    toLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.wrap-home {
  overflow: hidden;
  width: 100%;

  .small {
    @media only screen and (min-width: 768px) {
      display: none;
    }

    .bg {
      .red,
      .yellow,
      .blue
      {
        opacity: 0;
        position: relative;
        animation: bgSmall 0.75s ease 2.5s forwards;
      }

      .red {
        // transform: translate(calc(62px + 14%), calc(-50% - 14%));
        left: 50%;
        margin-top: -50%;
        width: calc(375px - 62px);
        height: calc(375px - 62px);
        border-radius: 50%;
        border: 62px solid $color-main;
      }

      .yellow {
        // transform: translate(calc(-45px), 100px);
        top: -10px;
        left: -10%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #E0A01C;
      }

      .blue {
        // transform: translate(calc(-20%), 500%);
        top: 90vh;
        left: -10%;
        width: 155px;
        height: 155px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('~@/assets/icons/path-blue.svg');

        @media only screen and (min-width: 375px) {
          top: 72vh;
        }

        @media only screen and (min-width: 425px) {
          top: 66vh;
        }
      }
    }

    .mobile {
      height: auto;

      .uk-container {
        position: relative;
        margin-top: -36vh;
        z-index: 1;
        // width: 100%;

        @media only screen and (min-width: 768px) {
          display: none;
        }

        .box-center {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50vh;

          h2 {
            opacity: 0;
            margin-top: 24px;
            font-family: 'DB Ozone X Bd';
            font-size: 5rem;
            animation: text-let 2s $ease forwards;
          }

          img {
            opacity: 0;
            height: 60%;
            animation: logo 3s $ease 0.5s forwards;
          }
        }

        .box-detail {
          margin-top: -50px;

          .box-item-red,
          .box-item-green,
          .box-item-blue,
          .box-item-yellow,
          .box-item-orange,
          .box-item-menu
          {
            display: flex;
            justify-content: center;
            // margin: 22px 0;
            height: 100%;

            .wrap {
              display: flex;
              justify-content: flex-start;
              width: 100%;

              .box-icon {
                // transform: translate(0, 70px);
                margin-top: 70px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: rgba(255, 0, 0, 0.05);

                div {
                  position: relative;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url('~@/assets/icons/ic-cup.svg');
                  margin-top: 6px;
                  width: auto;
                  height: 80%;

                  p {
                    padding-top: 8px;
                    font-family: 'DB Ozone X Bd';
                    text-align: center;
                    font-size: 1.75rem;
                    color: $color-main;
                  }
                }
              }

              .item {
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('~@/assets/icons/item-red.svg');
                width: 177px;
                height: 75px;

                p {
                  padding: 16px 0 0 6px;
                  text-align: center;
                  font-family: 'DB Ozone X Bd';
                  font-size: 1.125rem;
                }
              }
            }
          }

          .box-item-red {
            .wrap {
              .box-icon {
                opacity: 0;
                transform-origin: center;
                animation: iconOdd 0.75s ease 1s infinite alternate forwards;
              }

              .item {
                // transform: rotate(30deg)
                //            translate(30px, 50px);
                opacity: 0;
                animation: itemBoxOdd 1s $ease 1s forwards;
              }
            }
          }

          .box-item-green {
            .wrap {
              justify-content: flex-end;

              .box-icon {
                opacity: 0;
                animation: iconEven 0.75s ease 1.5s infinite alternate forwards;

                div {
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url('~@/assets/icons/ic-watch.svg');

                  img {
                    padding: 12px 0 0 22px;
                    height: 66%;
                  }
                }
              }

              .item {
                opacity: 0;
                background-image: url('~@/assets/icons/item-green.svg');
                animation: itemBoxEven 1s $ease 1.5s forwards;

                p {
                  padding: 22px 6px 0 0;
                }
              }
            }
          }

          .box-item-blue {
            .wrap {
              justify-content: flex-end;
              .box-icon {
                opacity: 0;
                animation: iconEven 0.75s ease 2.5s infinite alternate forwards;

                div {
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url('~@/assets/icons/ic-mock-phone.svg');

                  img {
                    padding: 16px 0 0 23px;
                    height: 68%;
                  }
                }
              }

              .item {
                opacity: 0;
                background-image: url('~@/assets/icons/item-blue.svg');
                animation: itemBoxEven 1s $ease 2.5s forwards;

                p {
                  padding: 26px 0 0 6px;
                  line-height: 1;
                }
              }
            }
          }

          .box-item-yellow {
            .wrap {
              justify-content: flex-end;

              .box-icon {
                opacity: 0;
                animation: iconEven 0.75s ease 2.5s infinite alternate forwards;

                div {
                  background-repeat: no-repeat;
                  background-position: center;
                  background-image: url('~@/assets/icons/ic-calendar.svg');

                  img {
                    padding: 16px 0 0 23px;
                    height: 68%;
                  }
                }
              }

              .item {
                opacity: 0;
                background-image: url('~@/assets/icons/item-yellow.svg');
                animation: itemBoxEven 1s $ease 2.5s forwards;

                p {
                  padding: 16px 6px 0 0;
                }
              }
            }
          }

          .box-item-orange {
            .wrap {
              justify-content: flex-end;

              .box-icon {
                opacity: 0;
                // animation: iconOdd 0.75s ease 2.5s infinite alternate forwards;
                animation: iconEven 0.75s ease 1.5s infinite alternate forwards;

                div {
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 50%;
                  background-image: url('~@/assets/icons/ic-picker.svg');
                }
              }

              .item {
                opacity: 0;
                background-image: url('~@/assets/icons/item-orange.svg');
                // animation: itemBoxEven 1s $ease 2.5s forwards;
                animation: itemBoxEven 1s $ease 1.5s forwards;

                p {
                  padding: 26px 0 0 6px;
                  line-height: 1;
                }
              }
            }
          }

          .box-item-menu {
            .wrap {
              // justify-content: flex-end;

              .box-icon {
                opacity: 0;
                // animation: iconOdd 0.75s ease 2.5s infinite alternate forwards;
                animation: iconOdd 0.75s ease 2s infinite alternate forwards;

                div {
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 50%;
                  background-image: url('~@/assets/icons/ic-menu.svg');
                }
              }

              .item {
                opacity: 0;
                background-image: url('~@/assets/icons/item-menu.svg');
                // animation: itemBoxEven 1s $ease 2.5s forwards;
                animation: itemBoxOdd 1s $ease 2s forwards;

                p {
                  padding: 26px 0 0 6px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }

  .medium {
    display: none;

    @media only screen and (min-width: 768px) {
      display: block;
      width: 100vw;
      height: 100vh;
      // background: yellowgreen;
    }

    .bg {
      .yellow,
      .blue
      {
        opacity: 0;
        position: relative;
        border-radius: 50%;
        animation: bgSmall 0.75s ease 2.5s forwards;
      }

      .red {
        opacity: 0;
        position: relative;
        top: 0;
        left: 100vw;
        width: calc(788px - 62px);
        height: calc(788px - 62px);
        border-radius: 50%;
        border: 100px solid $color-main;
        // transform: translate(calc(-50% + 4vw), calc(-50% - 4vw));

        animation: bgRedSmall 0.75s ease 2.5s forwards;
      }

      .yellow {
        // transform: translate(calc(-45px), 100px);
        top: -700px;
        left: 100px;
        width: 111px;
        height: 111px;
        background-color: #E0A01C;
      }

      .blue {
        // transform: translate(calc(-20%), 500%);
        margin-top: -1020px;
        top: 100vh;
        left: 0;
        width: 255px;
        height: 255px;
        border-radius: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('~@/assets/icons/path-blue.svg');

        @media only screen and (min-width: 768px) {
          bottom: 250px;
        }

        @media only screen and (min-width: 1024px) {
          bottom: 250px;
        }
      }
    }

    .box-logo {
      display: none;

      @media only screen and (min-width: 768px) {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .box-center {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
          opacity: 0;
          margin-top: 38px;
          font-family: 'DB Ozone X Bd';
          font-size: 14.5vh;
          // font-size: 8.5rem;
          animation: text-let 2s $ease forwards;
        }

        img {
          opacity: 0;
          max-height: 50vh;
          animation: logo 3s $ease 0.5s forwards;
        }
      }
    }

    .box-detail {
      position: relative;
      display: block;
      top: -430px;

      .box-item-red,
      .box-item-green,
      .box-item-blue,
      .box-item-yellow,
      .box-item-orange,
      .box-item-menu
      {
        position: relative;

        .wrap {
          display: flex;
          justify-content: flex-start;

          .box-icon {
            margin-top: 70px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: rgba(255, 0, 0, 0.05);

            div {
              cursor: pointer;
              position: relative;
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('~@/assets/icons/ic-cup.svg');
              margin-top: 6px;
              width: auto;
              height: 80%;

              p {
                padding-top: 8px;
                font-family: 'DB Ozone X Bd';
                text-align: center;
                font-size: 1.75rem;
                color: $color-main;
              }
            }
          }

          .item {
            // display: none;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('~@/assets/icons/item-red.svg');
            width: 177px;
            height: 75px;

            p {
              padding: 16px 0 0 6px;
              text-align: center;
              font-family: 'DB Ozone X Bd';
              font-size: 1.125rem;
            }
          }
        }
      }

      .box-item-red {
        // transform: translate(15vw, 55vh);
        margin-top: 500px;
        left: 18vw;
        top: -5vh;

        .wrap {
          .box-icon {
            opacity: 0;
            transform-origin: center;
            animation: iconRedMedium 2s $ease 1s forwards,
                       iconOdd 0.8s ease 1.5s infinite alternate forwards;
          }

          .item {
            opacity: 0;
          }

          .hover-box-red {
            animation: itemBoxOdd 1s $ease forwards;
          }
        }
      }

      .box-item-green {
        left: 88vw;
        top: -20vh;
        margin-top: -400px;

        .wrap {
          .box-icon {
            opacity: 0;
            animation: iconGreenMedium 2s $ease 1s forwards,
                       iconEven 0.6s ease 1.5s infinite alternate forwards;

            // animation: iconEven 0.75s ease 1.5s infinite alternate forwards;

            div {
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('~@/assets/icons/ic-watch.svg');

              img {
                padding: 12px 0 0 22px;
                height: 66%;
              }
            }
          }

          .item {
            opacity: 0;
            background-image: url('~@/assets/icons/item-green.svg');

            p {
              padding: 22px 6px 0 0;
            }
          }

          .hover-box-green {
            animation: itemBoxOdd 1s $ease forwards;
          }
        }
      }

      .box-item-blue {
        margin-top: -180px;
        left: 16vw;
        top: 50vh;
        // left: 30vw;
        // top: 30vh;

        .wrap {
          .box-icon {
            opacity: 0;
            animation: iconBlueMedium 2s $ease 1s forwards,
                       iconOdd 0.8s ease 1.5s infinite alternate forwards;

            div {
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('~@/assets/icons/ic-mock-phone.svg');

              img {
                padding: 16px 0 0 23px;
                height: 68%;
              }
            }
          }

          .item {
            opacity: 0;
            background-image: url('~@/assets/icons/item-blue.svg');

            p {
              padding: 22px 0 0 6px;
              line-height: 1;
            }
          }

          .hover-box-blue {
            animation: itemBoxEven 1s $ease forwards;
          }
        }
      }

      .box-item-yellow {
        // transform: translate(-10vw, 35vh);
        // position: relative;
        // z-index: 2;
        left: 65vw;
        top: -40vh;

        .wrap {
          // justify-content: flex-end;

          .box-icon {
            opacity: 0;
            animation: iconYellowMedium 2s $ease 1s forwards,
                       iconEven 0.6s ease 1.5s infinite alternate forwards;
            // animation: iconEven 0.75s ease 2.5s infinite alternate forwards;

            div {
              background-repeat: no-repeat;
              background-position: center;
              background-image: url('~@/assets/icons/ic-calendar.svg');

              img {
                padding: 16px 0 0 23px;
                height: 68%;
              }
            }
          }

          .item {
            opacity: 0;
            background-image: url('~@/assets/icons/item-yellow.svg');

            p {
              padding: 16px 6px 0 0;
            }
          }

          .hover-box-yellow {
            animation: itemBoxEven 1s $ease forwards;
          }
        }
      }

      .box-item-orange {
        margin-top: -600px;
        left: 72vw;
        top: 70vh;

        .wrap {
          .box-icon {
            opacity: 0;
            animation: iconOrangeMedium 2s $ease 1s forwards,
                       iconOdd 0.9s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              background-repeat: no-repeat;
              background-position: center;
              background-size: 50%;
              background-image: url('~@/assets/icons/ic-picker.svg');
            }
          }

          .item {
            opacity: 0;
            background-image: url('~@/assets/icons/item-orange.svg');

            p {
              padding: 26px 0 0 6px;
              line-height: 1;
            }
          }

          .hover-box-orange {
            animation: itemBoxOdd 1s $ease forwards;
          }
        }
      }

      .box-item-menu {
        margin-top: -250px;
        left: 70vw;
        top: 110vh;

        .wrap {
          .box-icon {
            opacity: 0;
            animation: iconOrangeMedium 2s $ease 1s forwards,
                       iconEven 1.25s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              background-repeat: no-repeat;
              background-position: center;
              background-size: 50%;
              background-image: url('~@/assets/icons/ic-menu.svg');
            }
          }

          .item {
            opacity: 0;
            background-image: url('~@/assets/icons/item-menu.svg');

            p {
              padding: 26px 0 0 6px;
              line-height: 1;
            }
          }

          .hover-box-menu {
            animation: itemBoxEven 1s $ease forwards;
          }
        }
      }

      .box-item-twitter,
      .box-item-facebook,
      .box-item-line,
      .box-item-instagram
      {
        cursor: pointer;
        position: absolute;

        .box-icon {
          transition: 1s $ease;
        }

        .box-icon:hover {
          transform: scale(1.2);
        }
      }

      .box-item-twitter {
        // transform: translate(10vw, 10vh);
        left: 12vw;
        top: 36vh;

        // @media only screen and (min-width: 1366px) {
        //   left: 22vw;
        // }

        .wrap {
          .box-icon {
            // animation: iconOrangeMedium 2s $ease 1s forwards,
            //            iconOdd 0.7s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              opacity: 0;
              animation: iconTwitterMedium 2s $ease 2s forwards;
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              background-image: url('~@/assets/icons/ic-twitter-home.svg');
            }
          }
        }
      }

      .box-item-facebook {
        // transform: translate(30vw, -10vh);
        left: 35vw;
        // top: 12vh;
        top: calc(-360px + 50vh);
        transition: 1s $ease;

        .wrap {
          .box-icon {
            animation: iconFacebookMedium 2s $ease 1s forwards;
            // animation: iconOrangeMedium 2s $ease 1s forwards,
            //            iconOdd 0.7s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              opacity: 0;
              animation: iconFacebookMedium 2s $ease 2s forwards;
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 50%;
              background-image: url('~@/assets/icons/ic-facebook-home.svg');
            }
          }
        }
      }

      .box-item-facebook:hover {
        transform: scale(1.2);
      }

      .box-item-line {
        // transform: translate(80vw, -10vh);
        left: 74vw;
        top: 100vh;
        margin-top: -350px;

        // @media only screen and (min-width: 768px) {
        //   left: 88vw;
        //   top: -25vh;
        // }

        // @media only screen and (min-width: 1024px) {
        //   top: -42vh;
        // }

        // @media only screen and (min-width: 1366px) {
        //   left: 75vw;
        //   top: -45vh;
        // }

        .wrap {
          .box-icon {
            // opacity: 0;
            // animation: iconLineMedium 2s $ease 1s forwards;
            // animation: iconOrangeMedium 2s $ease 1s forwards,
            //            iconOdd 0.7s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              opacity: 0;
              animation: iconFacebookMedium 2s $ease 2s forwards;
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              background-image: url('~@/assets/icons/ic-line-home.svg');
            }
          }
        }
      }

      .box-item-instagram {
        // transform: translate(82vw, 20vh);
        left: 52vw;
        top: 100vh;
        margin-top: -240px;

        // @media only screen and (min-width: 768px) {
        //   left: 72vw;
        //   top: 5vh;
        // }

        // @media only screen and (min-width: 1024px) {
        //   top: -10vh;
        // }

        // @media only screen and (min-width: 1366px) {
        //   top: -5vh;
        // }

        .wrap {
          .box-icon {
            // animation: iconOrangeMedium 2s $ease 1s forwards,
            //            iconOdd 0.7s ease 1.5s infinite alternate forwards;
            // animation: iconOdd 0.75s ease 3s infinite alternate forwards;

            div {
              opacity: 0;
              animation: iconInstagramMedium 2s $ease 2s forwards;
              width: 40px;
              height: 40px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100%;
              background-image: url('~@/assets/icons/ic-instagram-home.svg');
            }
          }
        }
      }
    }
  }
}

@keyframes text-let {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes logo {
  0% {
    opacity: 0;
    transform: scale(0.6);
    transform: translateY(10px);
  }

  100% {
    transform: scale(1);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes iconOdd {
  0% {
    opacity: 1;
    transform: rotate(-10deg)
               scale(1.1);
  }

  50% {
    transform: rotate(0)
               scale(1);
  }

  100% {
    opacity: 1;
    transform: rotate(10deg)
               scale(1.1);
  }
}

@keyframes iconEven {
  0% {
    opacity: 1;
    transform: rotate(-10deg)
               scale(1.1);
  }

  50% {
    transform: rotate(0)
               scale(1);
  }

  100% {
    opacity: 1;
    transform: rotate(10deg)
               scale(1.1);
  }
}

@keyframes itemBoxOdd {
  0% {
    opacity: 0;
    transform: rotate(30deg)
               translate(30px, 50px);
  }

  100% {
    opacity: 1;
    transform: rotate(0)
               translate(0);
  }
}

@keyframes itemBoxEven {
  0% {
    opacity: 0;
    transform: rotate(-30deg)
               translate(30px, 50px);
  }

  100% {
    opacity: 0;
    transform: rotate(0)
               translate(0);
  }
}

@keyframes itemBoxEven {
  0% {
    opacity: 0;
    transform: rotate(-30deg)
               translate(30px, 50px);
  }

  100% {
    opacity: 1;
    transform: rotate(0)
               translate(0);
  }
}

@keyframes iconRedMedium {
  0% {
    opacity: 0;
    transform: translate(30%, 30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 30%);
  }
}

@keyframes iconGreenMedium {
  0% {
    opacity: 0;
    transform: translate(-30%, -30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconBlueMedium {
  0% {
    opacity: 0;
    transform: translate(30%, -30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconYellowMedium {
  0% {
    opacity: 0;
    transform: translate(-30%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconOrangeMedium {
  0% {
    opacity: 0;
    transform: translate(30%, 30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconTwitterMedium {
  0% {
    opacity: 0;
    transform: translate(30%, 0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconFacebookMedium {
  0% {
    opacity: 0;
    transform: translate(30%, 30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconLineMedium {
  0% {
    opacity: 0;
    transform: translate(-30%, 30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes iconInstagramMedium {
  0% {
    opacity: 0;
    transform: translate(-30%, -30%);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes bgSmall {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bgRedSmall {
  0% {
    opacity: 0;
    transform: translate(calc(-50% + 4vw), calc(-50% - 4vw)) scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    transform: translate(calc(-50% + 4vw), calc(-50% - 4vw)) scale(1);
  }
}
</style>
